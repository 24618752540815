
class favoriteBtnComponent extends Component {

    static name() {
        return "favoriteBtnComponent";
    }

    static componentName() {
        return "favoriteBtnComponent";
    }

    getProps() {
        return  ['itemCode'];
    }

    data() {
        return {
            mustUpdate:false,
        };
    }

    mounted() {
        return function () {
            this. mustUpdate = this.$store.getters.getFavorites.indexOf(this.itemCode) != -1;
        };
    }

    getComputed() {
        return {
            iconName (){
                if(!this.mustUpdate)
                    return  "regular/heart";
                return "heart";
            },
            title (){
                if(this.mustUpdate)
                    return  this.tr('Remove From Favorite');
                return this.tr('Add To Favorite');
            },
            className (){
                if(this.mustUpdate)
                    return "is-favorite";
                return "";
            }
        };
    }

    getMethods() {
        return {
            toggleFavorite:this.toggleFavorite,
        };
    }

    toggleFavorite(){
        this.$store.dispatch('toggleFavourite',this.itemCode);
        this.mustUpdate = !this.mustUpdate;
        //console.log('Item After',this.mustUpdate);
    }


    getTemplate() {
        return ` <p :key="itemCode" @click.stop="toggleFavorite()" :title="title" :class="[className]">
                    <icon class="icon fa-heart" :name="iconName" ></icon>
               </p>`;
    }
}

favoriteBtnComponent.registerComponent();





/*

Vue.component('favoriteBtn', {
    props:['itemCode'],
    data:function (){
        return {
            mustUpdate:false,
        }
    },
    mounted (){
        this. mustUpdate=this.$store.getters.getFavorites.indexOf(this.itemCode)!=-1;
    },
    computed:{
        iconName (){
            if(!this.mustUpdate)
                return  "regular/heart";
            return "heart";
        },
        title (){
            if(this.mustUpdate)
                return  this.tr('Remove From Favorite');
            return this.tr('Add To Favorite');
        }
    },
    methods:{
        toggleFavorite (){
            this.$store.dispatch('toggleFavourite',this.itemCode);
            this.mustUpdate = !this.mustUpdate;
            //console.log('Item After',this.mustUpdate);
        },
    },
    template:` <p :key="itemCode" @click="toggleFavorite()" :title="title">
                    <icon class="icon fa-heart" :name="iconName" ></icon>
               </p>`
})*/
